import { ScrollSectionDirective } from './scroll-section.directive';
import { Directive } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appScrollManager]'
})
export class ScrollManagerDirective {
  private sections = new Map<string | number, ScrollSectionDirective>();

  scroll(id: string | number): void {
    this.sections.get(id).scroll();
  }

  register(section: ScrollSectionDirective): void {
    this.sections.set(section.id, section);
  }

  remove(section: ScrollSectionDirective): void {
    this.sections.delete(section.id);
  }
}
